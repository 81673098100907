var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, useLoaderData } from 'react-router-dom';
import { CpaProductMetadata, ProductSpecialRestrictionCode, } from '@millerknoll/pub-api-schema';
import { PageContainer } from 'components/Shared/PageContainer/PageContainer';
import { cpaService } from 'services/api/cpa-service';
import { validateWithCache } from 'services/api/product-service';
import { useEffect, useMemo } from 'react';
import { CpaProductType, PRODUCT_CLASS_FOR_SPECIAL_ORDERS, PRODUCT_CLASS_FOR_WORK_ORDERS, WORK_ORDER_DEFAULT_DISCOUNT_CODE, WORK_ORDER_DEFAULT_DISCOUNT_DESC, WORK_ORDER_DEFAULT_GENERIC_CODE, WORK_ORDER_DEFAULT_PRICE_LINE_CODE, WORK_ORDER_DEFAULT_PRICE_LINE_DESC, WORK_ORDER_DEFAULT_PRICE_LINE_MODEL_CODE, determineProductClass, getPricingTableData, } from 'utils/cpa-utils';
import { cpaProductMapperHelper } from 'utils/cpa-product-mapper-helper';
import { useAuth } from 'services/auth/AuthProvider';
import { CpaRole } from 'models/auth/User';
import { CpaProductHeader } from '../CpaProductHeader/CpaProductHeader';
import { CpaProductBody } from '../CpaProductBody/CpaProductBody';
import { useCpaProduct } from './CpaProductContextProvider';
import { CpaProductMode } from './CpaProductReducer';
export function CpaProduct() {
    var _a = useLoaderData(), cpn = _a.cpn, currentProduct = _a.currentProduct, currentProductOriginal = _a.currentProductOriginal, standardProduct = _a.standardProduct, mode = _a.mode, productType = _a.productType, pricingTableData = _a.pricingTableData, currentProductMetadata = _a.currentProductMetadata, pricingTableDataOriginal = _a.pricingTableDataOriginal, standardProductPrice = _a.standardProductPrice, drawingFile = _a.drawingFile;
    var cpaProductContext = useCpaProduct();
    var user = useAuth().user;
    var accessDenied = useMemo(function () { return checkAccessDenied(user, productType); }, [productType, user]);
    useEffect(function () {
        cpaProductContext === null || cpaProductContext === void 0 ? void 0 : cpaProductContext.dispatch({
            type: 'UPDATE_FULL',
            payload: {
                cpn: cpn,
                currentProduct: currentProduct,
                currentProductMetadata: currentProductMetadata,
                currentProductOriginal: currentProductOriginal,
                mode: mode,
                productType: productType,
                standardProduct: standardProduct,
                pricingTableData: pricingTableData,
                pricingTableDataOriginal: pricingTableDataOriginal,
                standardProductPrice: standardProductPrice,
                drawingFile: drawingFile,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (accessDenied)
        return _jsx(Navigate, { to: "/", state: { accessDenied: true, message: 'Contact Customs Security Group' } });
    return (_jsxs(PageContainer, __assign({ rowGap: 100, margin: "60px auto 60px auto" }, { children: [_jsx(CpaProductHeader, {}), _jsx(CpaProductBody, {})] })));
}
function checkAccessDenied(user, productType) {
    var accessDenied = false;
    if ((user === null || user === void 0 ? void 0 : user.cpaRole) === CpaRole.SPECIALS && productType !== CpaProductType.SPECIAL_ORDER) {
        accessDenied = true;
    }
    else if ((user === null || user === void 0 ? void 0 : user.cpaRole) === CpaRole.WORKORDER && productType !== CpaProductType.WORK_ORDER) {
        accessDenied = true;
    }
    return accessDenied;
}
export function cpaProductLoader(_a) {
    var _b, _c, _d, _e, _f;
    var params = _a.params;
    return __awaiter(this, void 0, void 0, function () {
        var stndProdRef, cpn, mode, inAddEditMode, getProductRsp, _g, standardProduct, isStndProdRefWorkOrder, validateApiRsp, validateApiRspBody, currentProduct, currentProductMetadata, validateApiRsp, productType, currentProductOriginal, pricingRsp, pricingTableData, pricingTableDataOriginal, validationAPIRsp;
        var _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    stndProdRef = params.stndProdRef, cpn = params.cpn;
                    if (!cpn) {
                        return [2 /*return*/, {}];
                    }
                    cpn = cpn.toUpperCase();
                    inAddEditMode = sessionStorage.getItem("".concat(cpn, "_inAddEditMode"));
                    if (!(inAddEditMode !== CpaProductMode.ADD)) return [3 /*break*/, 2];
                    return [4 /*yield*/, cpaService.getProduct(cpn, false)];
                case 1:
                    _g = _j.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _g = undefined;
                    _j.label = 3;
                case 3:
                    getProductRsp = _g;
                    if ((getProductRsp === null || getProductRsp === void 0 ? void 0 : getProductRsp.status) !== 200) {
                        mode = CpaProductMode.ADD;
                    }
                    else {
                        mode = CpaProductMode.EDIT;
                    }
                    /**
                     * if
                     */
                    if (!stndProdRef && mode === CpaProductMode.ADD) {
                        throw Error('Standard Product Reference Missing in ADD Mode');
                    }
                    /**
                     * in Edit Mode use standard product reference from cpa product to build standardProduct
                     */
                    if (mode === CpaProductMode.EDIT) {
                        stndProdRef = (_b = getProductRsp === null || getProductRsp === void 0 ? void 0 : getProductRsp.body.product) === null || _b === void 0 ? void 0 : _b.standardProductReference;
                    }
                    isStndProdRefWorkOrder = false;
                    if (!stndProdRef) return [3 /*break*/, 5];
                    return [4 /*yield*/, validateWithCache({
                            productNbr: stndProdRef,
                        })];
                case 4:
                    validateApiRsp = _j.sent();
                    if (validateApiRsp.status === 200) {
                        validateApiRspBody = validateApiRsp.body;
                        standardProduct = cpaProductMapperHelper.convertParsedResultsToCpaProduct(validateApiRspBody);
                        isStndProdRefWorkOrder = validateApiRspBody.productClass === PRODUCT_CLASS_FOR_WORK_ORDERS;
                    }
                    _j.label = 5;
                case 5:
                    if (!(mode === CpaProductMode.ADD)) return [3 /*break*/, 8];
                    if (!standardProduct) {
                        throw Error('Standard Product could not be build from standard product reference entered');
                    }
                    currentProduct = standardProduct;
                    if (!(standardProduct.productClass === PRODUCT_CLASS_FOR_SPECIAL_ORDERS)) return [3 /*break*/, 7];
                    return [4 /*yield*/, validateWithCache({
                            productNbr: standardProduct.standardProductReference,
                        })];
                case 6:
                    validateApiRsp = _j.sent();
                    if (validateApiRsp.status === 200) {
                        currentProduct.standardProductReference =
                            validateApiRsp.body.standardProductReference ||
                                standardProduct.standardProductReference;
                    }
                    _j.label = 7;
                case 7:
                    if (determineProductClass(cpn) === CpaProductType.SPECIAL_ORDER) {
                        currentProduct.productClass = PRODUCT_CLASS_FOR_SPECIAL_ORDERS;
                        // default prerelease restriction to true on product add
                        currentProduct.restrictions = __assign(__assign({}, currentProduct.restrictions), (_h = {}, _h[ProductSpecialRestrictionCode === null || ProductSpecialRestrictionCode === void 0 ? void 0 : ProductSpecialRestrictionCode.PRERELEASE] = { isActive: true }, _h));
                    }
                    else {
                        currentProduct.productClass = PRODUCT_CLASS_FOR_WORK_ORDERS;
                        currentProduct.productLineCode = WORK_ORDER_DEFAULT_PRICE_LINE_CODE;
                        currentProduct.productLineDesc = WORK_ORDER_DEFAULT_PRICE_LINE_DESC;
                        currentProduct.productLinemodelCode = WORK_ORDER_DEFAULT_PRICE_LINE_MODEL_CODE;
                        currentProduct.discountCode = WORK_ORDER_DEFAULT_DISCOUNT_CODE;
                        currentProduct.discountDesc = WORK_ORDER_DEFAULT_DISCOUNT_DESC;
                        currentProduct.genericCode = WORK_ORDER_DEFAULT_GENERIC_CODE;
                    }
                    currentProductMetadata = {
                        sourceSystem: CpaProductMetadata.sourceSystem.CUSTOM,
                    };
                    return [3 /*break*/, 9];
                case 8:
                    if ((_c = getProductRsp === null || getProductRsp === void 0 ? void 0 : getProductRsp.body) === null || _c === void 0 ? void 0 : _c.product) {
                        currentProduct = (_d = getProductRsp === null || getProductRsp === void 0 ? void 0 : getProductRsp.body) === null || _d === void 0 ? void 0 : _d.product;
                        currentProductMetadata = ((_e = getProductRsp === null || getProductRsp === void 0 ? void 0 : getProductRsp.body) === null || _e === void 0 ? void 0 : _e.metadata) || {};
                    }
                    else {
                        return [2 /*return*/, {}];
                    }
                    _j.label = 9;
                case 9:
                    currentProduct.startDate = new Date().toISOString();
                    if (currentProduct.productClass === 'OPTIONS') {
                        productType = CpaProductType.SPECIAL_ORDER;
                    }
                    else {
                        productType = CpaProductType.WORK_ORDER;
                    }
                    if (mode === CpaProductMode.EDIT) {
                        currentProductOriginal = JSON.parse(JSON.stringify(currentProduct));
                    }
                    return [4 /*yield*/, cpaService.getPricing(cpn)];
                case 10:
                    pricingRsp = _j.sent();
                    pricingTableData = getPricingTableData(__spreadArray([], ((_f = pricingRsp.body) !== null && _f !== void 0 ? _f : []), true));
                    pricingTableDataOriginal = __assign({}, pricingTableData);
                    if (!(mode === CpaProductMode.ADD && productType === CpaProductType.SPECIAL_ORDER)) return [3 /*break*/, 12];
                    validationAPIRsp = sessionStorage.getItem("".concat(cpn, "_validationData"));
                    if (validationAPIRsp != null) {
                        currentProduct.salesCategory = JSON.parse(validationAPIRsp).body.salesCategory;
                    }
                    return [4 /*yield*/, cpaService.createProduct(cpn, currentProduct)];
                case 11:
                    _j.sent();
                    sessionStorage.setItem("".concat(cpn, "_inAddEditMode"), CpaProductMode.EDIT);
                    mode = CpaProductMode.EDIT;
                    _j.label = 12;
                case 12: return [2 /*return*/, {
                        cpn: cpn,
                        standardProduct: standardProduct,
                        currentProduct: currentProduct,
                        currentProductMetadata: currentProductMetadata,
                        currentProductOriginal: currentProductOriginal,
                        mode: mode,
                        productType: productType,
                        pricingTableData: pricingTableData,
                        pricingTableDataOriginal: pricingTableDataOriginal,
                    }];
            }
        });
    });
}
