var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material/utils';
import { getNonStandardOptions } from 'services/api/search-nso';
import { PubTable } from '../PubTable/PubTable';
import { StyledDialogV2 } from '../StyledDialogV2/StyledDialogV2';
import styles from './FeatureLookupModal.module.scss';
export function FeatureLookupModal(_a) {
    var _this = this;
    var isOpen = _a.isOpen, title = _a.title, tableDefinition = _a.tableDefinition, tableData = _a.tableData, children = _a.children, filterLabel = _a.filterLabel, tooltip = _a.tooltip, widthOverride = _a.widthOverride, isNso = _a.isNso, nsoMetadata = _a.nsoMetadata, minHeightOverride = _a.minHeightOverride, setIsOpen = _a.setIsOpen, changeCodeHandler = _a.changeCodeHandler, fireApiInModal = _a.fireApiInModal, updateRowOptions = _a.updateRowOptions, updateSearchTextInParent = _a.updateSearchTextInParent;
    var _b = useState(), tableDataValues = _b[0], setTableDataValues = _b[1];
    var _c = useState(), searchText = _c[0], setSearchText = _c[1];
    var getNsoOptions = useCallback(function (text) { return __awaiter(_this, void 0, void 0, function () {
        var req, rsp, reducedOptions;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    req = {
                        effectiveDate: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.effectiveDate,
                        featureId: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.featureId,
                        groupCode: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.groupCode,
                        productNbr: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.productNbr,
                        productVersion: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.productVersion,
                        filterText: text !== null && text !== void 0 ? text : undefined,
                        pageSize: 500,
                    };
                    return [4 /*yield*/, getNonStandardOptions(req)];
                case 1:
                    rsp = _b.sent();
                    reducedOptions = (_a = rsp.body.data) === null || _a === void 0 ? void 0 : _a.reduce(function (agg, el, index) {
                        var _a;
                        return (__assign(__assign({}, agg), (_a = {}, _a[index] = {
                            code: "".concat(el.optionCode, " - ").concat(el.optionDescription),
                            leadTime: el.minLeadTime ? el.minLeadTime.toString() : null,
                            gsaInd: el.gsaInd ? 'Y' : 'N',
                            mfgOptionModelCode: el.mfgOptionModelCode,
                            prcOptionModelCode: el.prcOptionModelCode,
                            isLast: rsp.body.data.length - 250 === index,
                        }, _a)));
                    }, {});
                    updateRowOptions === null || updateRowOptions === void 0 ? void 0 : updateRowOptions(rsp.body.data);
                    setTableDataValues(function () { return reducedOptions; });
                    return [2 /*return*/, req];
            }
        });
    }); }, [nsoMetadata, updateRowOptions]);
    useEffect(function () {
        if (!tableData && isNso) {
            getNsoOptions(searchText);
            return;
        }
        if ((!tableDataValues && tableData && Object.keys(tableData).length > 0) ||
            (Object.keys(tableDataValues).length < Object.keys(tableData).length &&
                tableData &&
                Object.keys(tableData).length > 0)) {
            setTableDataValues(tableData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData]);
    var handleRowClick = function (key) {
        changeCodeHandler(key);
    };
    var getNsoOptionsFromSearch = useCallback(function (text) { return __awaiter(_this, void 0, void 0, function () {
        var req, nsoOptions, sortByOptionCode, reducedOptions;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    req = {
                        effectiveDate: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.effectiveDate,
                        featureId: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.featureId,
                        groupCode: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.groupCode,
                        groupCodes: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.groupCodes,
                        productNbr: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.productNbr,
                        productVersion: nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.productVersion,
                        pageSize: 500,
                    };
                    if (text) {
                        req.filterText = text;
                    }
                    return [4 /*yield*/, getNonStandardOptions(req)];
                case 1:
                    nsoOptions = _b.sent();
                    if (nsoMetadata === null || nsoMetadata === void 0 ? void 0 : nsoMetadata.groupCodes) {
                        sortByOptionCode = nsoOptions.body.data.sort(function (a, b) {
                            return a.optionCode.localeCompare(b.optionCode);
                        });
                        nsoOptions.body.data = sortByOptionCode.sort(function (a, b) {
                            return (a === null || a === void 0 ? void 0 : a.nsoGroupCode).localeCompare(b === null || b === void 0 ? void 0 : b.nsoGroupCode);
                        });
                    }
                    updateRowOptions === null || updateRowOptions === void 0 ? void 0 : updateRowOptions(nsoOptions.body.data);
                    reducedOptions = (_a = nsoOptions.body.data) === null || _a === void 0 ? void 0 : _a.reduce(function (agg, el, index) {
                        var _a;
                        return (__assign(__assign({}, agg), (_a = {}, _a[index] = {
                            code: "".concat(el.optionCode, " - ").concat(el.optionDescription, " (").concat(el.nsoGroupCode, ")"),
                            leadTime: el.minLeadTime ? el.minLeadTime.toString() : null,
                            gsaInd: el.gsaInd ? 'Y' : 'N',
                            mfgOptionModelCode: el.mfgOptionModelCode,
                            prcOptionModelCode: el.prcOptionModelCode,
                            isLast: nsoOptions.body.data.length - 250 === index,
                        }, _a)));
                    }, {});
                    setTableDataValues(function () { return reducedOptions; });
                    return [2 /*return*/];
            }
        });
    }); }, [nsoMetadata, updateRowOptions]);
    var handleSearchInput = useMemo(function () {
        return debounce(function (searchTerm) {
            updateSearchTextInParent === null || updateSearchTextInParent === void 0 ? void 0 : updateSearchTextInParent(searchTerm);
            setSearchText(searchTerm);
            if (isNso) {
                setTableDataValues([]);
                getNsoOptionsFromSearch(searchTerm);
                return;
            }
            var filteredData = Object.values(tableData).filter(function (item) { var _a; return (_a = item.code) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchTerm.toLowerCase()); });
            setTableDataValues(filteredData);
        }, 350);
    }, [tableData, isNso, getNsoOptionsFromSearch, updateSearchTextInParent]);
    return (_jsxs(StyledDialogV2, __assign({ isOpen: isOpen, setIsOpen: setIsOpen, sizeProps: { width: widthOverride ? "".concat(widthOverride, "%") : '40%' }, rowGap: "16px", padding: "28px", title: title, tooltipText: tooltip, isNso: isNso, minHeightOverride: minHeightOverride }, { children: [children, filterLabel && (_jsx(Typography, __assign({ className: styles.inputLabel, sx: { marginBottom: '-12px' } }, { children: filterLabel }))), _jsx(TextField, { size: "small", onInput: function (event) {
                    handleSearchInput(event.target.value);
                } }), _jsx(Box, { children: _jsx(PubTable, { def: __assign(__assign({}, tableDefinition), { rowClickHandler: handleRowClick }), tableData: tableDataValues !== null && tableDataValues !== void 0 ? tableDataValues : {}, alternateRowBackgroundColor: false, nsoMetadata: isNso ? nsoMetadata : undefined, lengthOfTable: isNso && tableDataValues ? Object.keys(tableDataValues).length : undefined, searchText: searchText, fireApiInInvoker: fireApiInModal, boldRowsOnHover: true }) })] })));
}
