var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CpaPrice, } from '@millerknoll/pub-api-schema';
import { CpaRole } from 'models/auth/User';
import { cpaService } from 'services/api/cpa-service';
import { textFormatHelper } from './text-format-helper';
import { dateFormatHelper } from './date-format-helper';
import { fileUploadHelper } from './file-upload-helper';
export var PRODUCT_CLASS_FOR_SPECIAL_ORDERS = 'OPTIONS';
export var PRODUCT_CLASS_FOR_WORK_ORDERS = 'WORKORDER';
export var WORK_ORDER_DEFAULT_PRICE_LINE_CODE = 'OE';
export var WORK_ORDER_DEFAULT_PRICE_LINE_MODEL_CODE = 'RestrictedUseOEM';
export var WORK_ORDER_DEFAULT_DISCOUNT_CODE = 'OE';
export var WORK_ORDER_DEFAULT_GENERIC_CODE = 'OE';
export var WORK_ORDER_DEFAULT_PRICE_LINE_DESC = 'Restricted Use -OEM Product';
export var WORK_ORDER_DEFAULT_DISCOUNT_DESC = 'Restricted Use -OEM Product';
export var PRICING_CHANGES_REMOVE_HOLD_RESTRICTION_COMMENT = 'REMOVING HOLD AFTER PRICING CHANGES';
export var DEFAULT_WORKFLOW = 'PRERELEASE HOLD - PRODUCT CHANGE';
export var ADD_MODE_PRERELEASE_COMMENT = 'New Product - On Prelease Hold';
export var DEFAULT_FSCCHAINOFCUSTODY = 'NC-COC-004469';
export var BUSINESS_UNIT_CODE_VALUE_MAPPINGS = {
    HMINAConfigure: 'HMINAConfigure',
    KnollNAConfigure: 'KnollNAConfigure',
};
export var FSC_TYPE = {
    FSC_MIX_70: 'FSC Mix 70%',
    FSC_MIX_90: 'FSC Mix 90%',
    FSC_MIX_NO: 'FSC Mix No',
};
export var BRAND_CODE = {
    HMI: 'HMI',
    KNOLL: 'KNOLL',
    MERIDIAN: 'MERIDIAN',
};
export var COMPLEXITY = {
    BLANK: '',
    EASY: 'Easy',
    MEDIUM: 'Medium',
    HARD: 'Hard',
};
export var EXTRA_DATA_FIELD_NAMES = {
    LEAD_TIME: 'minLeadtime',
    GSA: 'gsa',
    FSC_TYPE: 'fscType',
    COMPLEXITY: 'complexity',
    WEIGHT_CLASS_CODE: 'weightClassCode',
    PRODUCT_FUNCTION_CODE: 'productFunctionCode',
    QUOTE_STATUS: 'quoteStatus',
    ENG_STATUS: 'engStatus',
    PROPRIETARY_IND: 'proprietaryInd',
    ENG_SITE: 'engSite',
    BUSINESS_UNIT_CODE: 'businessUnitCode',
    BRAND_CODE: 'brandCode',
};
export var EXTRA_DATA_RESTRICTION_FIELD_NAMES = {
    CUSTOMER: 'customer',
    DISCONTINUED: 'discontinued',
    PRERELEASE: 'prerelease',
    QUALITY: 'quality',
    UNIT_TEST: 'unitTest',
};
export var EXTRA_DATA_SALES_CATEGORY_FIELD_NAMES = {
    SALES_BRAND_CODE: 'brandCode',
    SALES_BRAND_DESC: 'brandDesc',
    SALES_CATEGORY_CODE: 'categoryCode',
    SALES_CATEGORY_DESC: 'categoryDesc',
    SALES_PRODUCT_LINE_CODE: 'salesProductLineCode',
    SALES_PRODUCT_LINE_DESC: 'salesProductLineDesc',
    SALES_SUB_CATEGORY_CODE: 'subCategoryCode',
    SUB_CATEGORY_DESC: 'subCategoryDesc',
    SALES_TYPE_CODE: 'typeCode',
    SALES_TYPE_DESC: 'typeDesc',
};
export var CpaAssetType;
(function (CpaAssetType) {
    CpaAssetType["TI"] = "TI";
})(CpaAssetType || (CpaAssetType = {}));
export var CpaProductType;
(function (CpaProductType) {
    CpaProductType["WORK_ORDER"] = "WORK_ORDER";
    CpaProductType["SPECIAL_ORDER"] = "SPECIAL_ORDER";
})(CpaProductType || (CpaProductType = {}));
export var CPA_NOTE_TYPES;
(function (CPA_NOTE_TYPES) {
    CPA_NOTE_TYPES["Price Logic"] = "PRICE_LOGIC";
    CPA_NOTE_TYPES["Engineering"] = "ENGINEERING";
    CPA_NOTE_TYPES["No Bid"] = "NO_BID";
    CPA_NOTE_TYPES["Proprietary"] = "PROPRIETARY";
    CPA_NOTE_TYPES["Restriction"] = "RESTRICTION";
})(CPA_NOTE_TYPES || (CPA_NOTE_TYPES = {}));
export var CUSTOM_PRODUCT_PREFIX;
(function (CUSTOM_PRODUCT_PREFIX) {
    CUSTOM_PRODUCT_PREFIX["Herman Miller (SHM)"] = "Herman Miller (SHM)";
    CUSTOM_PRODUCT_PREFIX["Knoll (SKQ)"] = "Knoll (SKQ)";
    CUSTOM_PRODUCT_PREFIX["Tailored Studio Herman Miller (STH)"] = "Tailored Studio Herman Miller (STH)";
    CUSTOM_PRODUCT_PREFIX["Tailored Studio Knoll (STQ)"] = "Tailored Studio Knoll (STQ)";
    CUSTOM_PRODUCT_PREFIX["Chair Plant (WOJ)"] = "Chair Plant (WOJ)";
    CUSTOM_PRODUCT_PREFIX["Wood Plant (WOY)"] = "Wood Plant (WOY)";
    CUSTOM_PRODUCT_PREFIX["Panels (WOP)"] = "Panels (WOP)";
})(CUSTOM_PRODUCT_PREFIX || (CUSTOM_PRODUCT_PREFIX = {}));
export var CUSTOM_PREFIXES = {
    'Herman Miller (SHM)': {
        prefix: 'SHM',
        type: CpaProductType.SPECIAL_ORDER,
    },
    'Knoll (SKQ)': {
        prefix: 'SKQ',
        type: CpaProductType.SPECIAL_ORDER,
    },
    'Tailored Studio Herman Miller (STH)': {
        prefix: 'STH',
        type: CpaProductType.SPECIAL_ORDER,
    },
    'Tailored Studio Knoll (STQ)': {
        prefix: 'STQ',
        type: CpaProductType.SPECIAL_ORDER,
    },
    'Chair Plant (WOJ)': {
        prefix: 'WOJ',
        type: CpaProductType.WORK_ORDER,
    },
    'Wood Plant (WOY)': {
        prefix: 'WOY',
        type: CpaProductType.WORK_ORDER,
    },
    'Panels (WOP)': {
        prefix: 'WOP',
        type: CpaProductType.WORK_ORDER,
    },
};
export var CPA_TABS = {
    description: { key: 'description', display: 'Description' },
    pricing: { key: 'pricing', display: 'Pricing' },
    notes: { key: 'notes', display: 'Notes' },
    drawing: { key: 'drawing', display: 'Drawing' },
    extraData: { key: 'extraData', display: 'Extra Data' },
};
export var defaultFileSelectProps = {
    allowedFileTypes: ['.gif'],
    maxFileSizeMb: 20,
};
export var determineProductClass = function (cpn) {
    var matchedEntry = Object.values(CUSTOM_PREFIXES).find(function (v) { return cpn.startsWith(v.prefix); });
    return (matchedEntry === null || matchedEntry === void 0 ? void 0 : matchedEntry.type) || CpaProductType.WORK_ORDER;
};
export function getPricingTableData(prices) {
    var priceData = {};
    prices === null || prices === void 0 ? void 0 : prices.forEach(function (price) {
        var _a, _b;
        priceData["".concat(price.startDate)] = {
            insertId: price.insertId,
            insertTimestamp: price.insertTimestamp,
            updateId: price.updateId,
            updateTimestamp: price.updateTimestamp,
            priceAdjustment: (_a = price.priceAdjustment) !== null && _a !== void 0 ? _a : 0,
            reviewIndicator: price.reviewIndicator === 'N' ? 'No' : 'Yes',
            priceType: textFormatHelper.snakeCaseToNormalText((_b = price.priceType) !== null && _b !== void 0 ? _b : CpaPrice.priceType.OVERRIDE, true),
            startDate: price.startDate ? dateFormatHelper.yyyyMMddHyphenated(price.startDate) : '',
            lastReviewDate: price.lastReviewDate ? dateFormatHelper.yyyyMMddHyphenated(price.lastReviewDate) : '',
        };
    });
    return priceData;
}
export function determineCustomProductPrefixes(userVal) {
    var filtered = [];
    if ((userVal === null || userVal === void 0 ? void 0 : userVal.cpaRole) === CpaRole.ALL)
        filtered = Object.values(CUSTOM_PRODUCT_PREFIX);
    else if ((userVal === null || userVal === void 0 ? void 0 : userVal.cpaRole) === CpaRole.SPECIALS) {
        var keysWithSP = Object.keys(CUSTOM_PREFIXES).filter(function (key) { return CUSTOM_PREFIXES[key].type === CpaProductType.SPECIAL_ORDER; });
        filtered = keysWithSP;
    }
    else if ((userVal === null || userVal === void 0 ? void 0 : userVal.cpaRole) === CpaRole.WORKORDER) {
        var keysWithWO = Object.keys(CUSTOM_PREFIXES).filter(function (key) { return CUSTOM_PREFIXES[key].type === CpaProductType.WORK_ORDER; });
        filtered = keysWithWO;
    }
    return filtered;
}
/**
 *
 * @param keyType InputFieldType value used for the corresponding key
 * @param tableKeys Array of keys from tableData
 * @param order Order for which to sort the keys
 * @returns Array of table keys sorted in asc/desc order based on key type. Defaults to desc order
 */
export function sortTableByKeyType(keyType, tableKeys, order) {
    if (keyType === undefined)
        return tableKeys;
    var updatedKeys = [];
    switch (keyType) {
        case 'DATE':
            updatedKeys = tableKeys.sort(function (a, b) { return (new Date(a).getTime() > new Date(b).getTime() ? 1 : -1); });
            break;
        default:
            updatedKeys = tableKeys.sort(function (a, b) { return (a > b ? 1 : -1); });
            break;
    }
    return order === 'asc' ? updatedKeys.reverse() : updatedKeys;
}
export function saveProduct(productContextData) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var savePromises, modePromise, assetPromise;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    savePromises = [];
                    if (productContextData.cpn && productContextData.currentProduct) {
                        if (productContextData.mode) {
                            modePromise = getSaveProductModePromise(productContextData.cpn, productContextData.currentProduct, productContextData.mode);
                            if (modePromise)
                                savePromises.push(modePromise);
                        }
                        if (productContextData.pricingTableData && productContextData.pricingTableDataOriginal) {
                            savePromises = savePromises.concat.apply(savePromises, getSaveProductPricingDataPromises(productContextData.cpn, productContextData.pricingTableData, productContextData.pricingTableDataOriginal));
                        }
                        if (productContextData.currentProduct.assets) {
                            assetPromise = getSaveProductAssetsPromise(productContextData.cpn, productContextData.currentProduct.assets, (_a = productContextData.currentProductOriginal) === null || _a === void 0 ? void 0 : _a.assets, productContextData.drawingFile);
                            if (assetPromise)
                                savePromises.push(assetPromise);
                        }
                    }
                    if (!(savePromises.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, Promise.all(savePromises)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
export function getSaveProductModePromise(cpn, currentProduct, mode) {
    switch (mode) {
        case 'ADD':
            // Create/POST
            return cpaService.createProduct(cpn, currentProduct);
        case 'EDIT':
            // Update/PUT
            return cpaService.updateProduct(cpn, currentProduct);
        default:
            return undefined;
    }
}
export function getSaveProductPricingDataPromises(cpn, pricingTableData, pricingTableDataOriginal) {
    var savePromises = [];
    Object.keys(pricingTableDataOriginal).forEach(function (rowKey) {
        var _a, _b, _c;
        if (!pricingTableData[rowKey]) {
            savePromises.push(cpaService.deletePrice(cpn, textFormatHelper.removeHyphens(pricingTableDataOriginal[rowKey].startDate), pricingTableDataOriginal[rowKey].workflow));
        }
        else if (JSON.stringify(pricingTableData[rowKey]) !== JSON.stringify(pricingTableDataOriginal[rowKey])) {
            var newRow = pricingTableData[rowKey];
            var priceRowRequest = __assign(__assign({}, newRow), { cpn: cpn, startDate: textFormatHelper.removeHyphens((_a = newRow.startDate) !== null && _a !== void 0 ? _a : ''), lastReviewDate: textFormatHelper.removeHyphens((_b = newRow.lastReviewDate) !== null && _b !== void 0 ? _b : ''), reviewIndicator: newRow.reviewIndicator === 'Yes' ? 'Y' : 'N', priceType: textFormatHelper.normalTextToSnakeCase((_c = newRow.priceType) !== null && _c !== void 0 ? _c : '', true), createAudit: true, workflow: newRow.workflow });
            savePromises.push(cpaService.updatePrice(priceRowRequest));
        }
    });
    Object.keys(pricingTableData)
        .filter(function (rowKey) { return !pricingTableDataOriginal[rowKey]; })
        .forEach(function (rowKey) {
        var _a, _b, _c;
        var newRow = pricingTableData[rowKey];
        var priceRowRequest = __assign(__assign({}, newRow), { cpn: cpn, startDate: textFormatHelper.removeHyphens((_a = newRow.startDate) !== null && _a !== void 0 ? _a : ''), lastReviewDate: textFormatHelper.removeHyphens((_b = newRow.lastReviewDate) !== null && _b !== void 0 ? _b : ''), reviewIndicator: newRow.reviewIndicator === 'Yes' ? 'Y' : 'N', priceType: textFormatHelper.normalTextToSnakeCase((_c = newRow.priceType) !== null && _c !== void 0 ? _c : '', true), createAudit: true, workflow: newRow.workflow });
        savePromises.push(cpaService.createPrice(priceRowRequest));
    });
    return savePromises;
}
export function getSaveProductAssetsPromise(cpn, assets, originalAssets, drawingFile) {
    var tiCurr = assets.find(function (asset) { return asset.type === CpaAssetType.TI; });
    var tiOriginal = originalAssets === null || originalAssets === void 0 ? void 0 : originalAssets.find(function (asset) { return asset.type === CpaAssetType.TI; });
    if (JSON.stringify(tiCurr) !== JSON.stringify(tiOriginal) && drawingFile) {
        return fileUploadHelper.uploadDrawing(drawingFile, cpn);
    }
    return undefined;
}
