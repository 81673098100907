import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { PubInputField } from 'components/Shared/PubInputField/PubInputField';
import { StyledButtonV2 } from 'components/Shared/StyledButtonV2/StyledButtonV2';
import { determineCustomProductPrefixes } from 'utils/cpa-utils';
import { InputFieldType } from 'models/Input/InputModels';
import { useProductValidate } from 'hooks/useProductValidate';
import { useAuth } from 'services/auth/AuthProvider';
import { CpaSearchForm } from '../CpaSearchForm/CpaSearchForm';
export function CpaSearchAddForm(_a) {
    var onSubmit = _a.onSubmit, addInProgress = _a.addInProgress, productNumber = _a.productNumber;
    var _b = useState(productNumber), stndProdRef = _b[0], setStndProdRef = _b[1];
    var _c = useState(), optionData = _c[0], setOptionData = _c[1];
    var _d = useState(), customProductType = _d[0], setCustomProductType = _d[1];
    var productValidate = useProductValidate(stndProdRef, 'Standard Product Reference');
    var _e = useState(false), stndProdRefInError = _e[0], setStndProdRefInError = _e[1];
    var _f = useState(false), productPrefixInError = _f[0], setProductPrefixInError = _f[1];
    var user = useAuth().user;
    useEffect(function () {
        setOptionData(determineCustomProductPrefixes(user));
    }, [user]);
    var ERROR_MESSAGES = {
        STANDARD_PRODUCT_REQUIRED: 'Standard Product Reference Must be Entered',
        STANDARD_PRODUCT_NO_SPACES_ALLOWED: 'Standard Product Reference Can not contain spaces',
        STANDARD_PRODUCT_INVALID_UNKNOWN: 'Standard Product Reference Invalid, UnKnown Error from Validation Api',
        CUSTOM_PRODUCT_TYPE_REQUIRED: 'Custom Product Type Must be Selected',
    };
    var handleAdd = function () {
        if (customProductType) {
            var validationData = {
                body: productValidate.body
            };
            onSubmit(stndProdRef, customProductType, validationData);
        }
    };
    var standProdRefInError = useCallback(function (val) {
        if (stndProdRefInError !== val) {
            setStndProdRefInError(val);
        }
    }, [stndProdRefInError]);
    return (_jsxs(CpaSearchForm, { children: [_jsx(PubInputField, { fieldType: InputFieldType.STRING, validations: {
                    required: ERROR_MESSAGES.STANDARD_PRODUCT_REQUIRED,
                    noSpaces: ERROR_MESSAGES.STANDARD_PRODUCT_NO_SPACES_ALLOWED,
                }, customErrorMsg: productValidate.errorMessage, inProgress: productValidate.loading, labels: {
                    left: 'Product to Copy',
                    right: 'Required',
                }, fieldValue: stndProdRef, helperText: "Enter the full product string to be used as the starting point for your new custom product.", dirtyCheck: true, inError: function (val) { return standProdRefInError(val); }, fieldValueUpdateHandler: function (val) { return setStndProdRef(val.trim()); } }), _jsx(PubInputField, { fieldType: InputFieldType.ENUM, fieldOptions: optionData, fieldValue: customProductType, labels: {
                    left: 'Custom Product Prefix',
                    right: 'Required',
                }, inError: function (val) {
                    if (productPrefixInError !== val) {
                        setProductPrefixInError(val);
                    }
                }, validations: {
                    required: ERROR_MESSAGES.CUSTOM_PRODUCT_TYPE_REQUIRED,
                }, fieldPlaceholderText: "Select Custom Prefix", dirtyCheck: true, fieldValueUpdateHandler: function (val) { return setCustomProductType(val); } }), _jsx(StyledButtonV2, { labelText: "Add Custom Product", disabled: productPrefixInError || stndProdRefInError || addInProgress, buttonAction: handleAdd })] }));
}
