var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { NonContractPriceModal } from 'components/Shared/NonContractPriceModal/NonContractPriceModal';
import { QuickPriceModal } from 'components/Shared/QuickPriceModal/QuickPriceModal';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CpaRole } from 'models/auth/User';
import { useAuth } from 'services/auth/AuthProvider';
import styles from './QuickLink.module.scss';
export function QuickLink(_a) {
    var label = _a.label, toRoute = _a.toRoute, icon = _a.icon, isDisabled = _a.isDisabled, modalData = _a.modalData, timestamp = _a.timestamp, channelVal = _a.channelVal, isCustomProduct = _a.isCustomProduct, action = _a.action, fireActionInParent = _a.fireActionInParent, isFetchingBaanData = _a.isFetchingBaanData, handleActionInParent = _a.handleActionInParent;
    var navigate = useNavigate();
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    function handleOpenModal() {
        if (!toRoute) {
            if (fireActionInParent) {
                handleActionInParent === null || handleActionInParent === void 0 ? void 0 : handleActionInParent();
                return;
            }
            setIsModalOpen(true);
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if (label !== 'Go to Product Info')
                navigate(toRoute);
        }
    }
    var user = useAuth().user;
    var accessDenied = useMemo(function () { return checkAccessDenied(user, isCustomProduct, isDisabled); }, [user, isCustomProduct, isDisabled]);
    function checkAccessDenied(userName, isCustomProductAvailable, isAllDisabled) {
        var isAccess = false;
        if (isCustomProductAvailable && !isAllDisabled) {
            if ((userName === null || userName === void 0 ? void 0 : userName.cpaRole) === CpaRole.SPECIALS ||
                (userName === null || userName === void 0 ? void 0 : userName.cpaRole) === CpaRole.WORKORDER ||
                (userName === null || userName === void 0 ? void 0 : userName.cpaRole) === CpaRole.ALL) {
                isAccess = false;
            }
            else {
                isAccess = true;
            }
        }
        else {
            isAccess = isAllDisabled;
        }
        return isAccess;
    }
    return (_jsxs(_Fragment, { children: [!toRoute ? (_jsxs(Box, __assign({ className: "".concat(styles.quickLink, " ").concat(accessDenied ? styles.disableLink : null), onClick: action
                    ? function () {
                        var parsedParamDetails = JSON.parse(sessionStorage.getItem('parsedProductPayload'));
                        if (parsedParamDetails === null || parsedParamDetails === void 0 ? void 0 : parsedParamDetails.fromParsedPage) {
                            sessionStorage.setItem('navigatedFrom', 'parsedProduct');
                        }
                        else {
                            sessionStorage.setItem('navigatedFrom', 'productValidate');
                        }
                    }
                    : function () { return handleOpenModal(); } }, { children: [isFetchingBaanData && fireActionInParent ? _jsx(CircularProgress, { size: "24px" }) : icon, _jsx(Box, __assign({ sx: { marginLeft: '10px' } }, { children: label }))] }))) : (_jsxs(Link, __assign({ className: "".concat(styles.quickLink, " ").concat(accessDenied && label !== 'Go to Product Info' ? styles.disableLink : null), reloadDocument: action === 'activateBuildMode', to: toRoute, target: label === 'Go to Product Info' ? '_blank' : '', onClick: action
                    ? function () {
                        var parsedParamDetails = JSON.parse(sessionStorage.getItem('parsedProductPayload'));
                        if (parsedParamDetails === null || parsedParamDetails === void 0 ? void 0 : parsedParamDetails.fromParsedPage) {
                            sessionStorage.setItem('navigatedFrom', 'parsedProduct');
                        }
                        else {
                            sessionStorage.setItem('navigatedFrom', 'productValidate');
                        }
                    }
                    : function () { return handleOpenModal(); } }, { children: [isFetchingBaanData && fireActionInParent ? _jsx(CircularProgress, { size: "24px" }) : icon, _jsx(Box, __assign({ sx: { marginLeft: '10px' } }, { children: label }))] }))), isModalOpen && label === 'View Quick Price' && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(QuickPriceModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, productReference: modalData.inputProductNbr, effectiveDate: timestamp !== null && timestamp !== void 0 ? timestamp : '', validationResp: modalData }) }))), isModalOpen && (label === 'View Price Without Contract' || label === 'View Contract Price') && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(NonContractPriceModal, { isOpen: isModalOpen, setIsOpen: setIsModalOpen, productReference: modalData.inputProductNbr, DiscountInfo: "".concat(modalData.discountDesc, " (").concat(modalData.discountCode, ")"), channel: channelVal, leadtime: (modalData === null || modalData === void 0 ? void 0 : modalData.minLeadtime) ? 'Assigned' : undefined, validationResp: modalData, contractPriceModalType: label }) })))] }));
}
