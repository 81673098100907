var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { catalogHelper } from 'utils/catalog-helper';
import { useExtractRequestOutput } from 'hooks/useExtractRequestOutput';
import { Loading } from 'components/Shared/Loading/AppLoading/Loading/Loading';
import { Link } from 'react-router-dom';
import { StyledTableContainer } from 'components/Shared/StyledTableContainer/StyledTableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledDialog } from 'components/Shared/StyledDialog/StyledDialog';
import { getTrackerForPartialKey } from 'services/api/search-catalogs';
import { CatalogRequestsModelHeader } from '../CatalogRequestsModalHeader/CatalogRequestsModalHeader';
import styles from './CatalogActivityModal.module.scss';
export function CatalogActivityModal(_a) {
    var _this = this;
    var _b;
    var request = _a.request, isOpen = _a.isOpen, closeModal = _a.closeModal;
    var _c = useExtractRequestOutput(request === null || request === void 0 ? void 0 : request.body.requestId), output = _c.body, loading = _c.loading, fileUrls = _c.fileUrls, filesLoading = _c.filesLoading;
    var _d = useState({}), filesMap = _d[0], setFilesMap = _d[1];
    var _e = useState(0), fileCount = _e[0], setFileCount = _e[1];
    var _f = useState([]), warnings = _f[0], setWarnings = _f[1];
    var _g = useState(false), warningsLoading = _g[0], setWarningsLoading = _g[1];
    var getWarningTrackers = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setWarningsLoading(true);
                    return [4 /*yield*/, getTrackerForPartialKey(request === null || request === void 0 ? void 0 : request.body.requestId, 'ACT_WARN')];
                case 1:
                    body = (_a.sent()).body;
                    setWarnings(body.map(function (el) { return el.body; }));
                    setWarningsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [request === null || request === void 0 ? void 0 : request.body.requestId]);
    useEffect(function () {
        var _a;
        if (((_a = request.body) === null || _a === void 0 ? void 0 : _a.hasWarning) === true) {
            getWarningTrackers();
        }
    }, [getWarningTrackers, request.body.hasWarning]);
    useEffect(function () {
        var _a, _b;
        var map = {};
        var files = Object.entries((_b = (_a = output === null || output === void 0 ? void 0 : output.body) === null || _a === void 0 ? void 0 : _a.files) !== null && _b !== void 0 ? _b : {});
        files.forEach(function (_a) {
            var k = _a[0], v = _a[1];
            var encodedSpacesVal = encodeURI(v);
            var signed = fileUrls.find(function (f) { return f.includes(encodedSpacesVal); });
            if (signed)
                map[v] = { type: k, signed: signed };
        });
        setFilesMap(map);
        setFileCount(files.length);
    }, [fileUrls, (_b = output === null || output === void 0 ? void 0 : output.body) === null || _b === void 0 ? void 0 : _b.files]);
    var renderRows = function (filePathValue) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: catalogHelper.getFileTypeDisplayText(filesMap[filePathValue].type.toLowerCase()) }), _jsx(TableCell, { children: filePathValue.substring(filePathValue.lastIndexOf('/') + 1) }), _jsx(TableCell, __assign({ width: "40px !important" }, { children: filesLoading ? (_jsx(CircularProgress, { size: 24 })) : (_jsx(Link, __assign({ className: styles.cellLink, to: filesMap[filePathValue].signed, download: filesMap[filePathValue].signed, target: "_blank noopener" }, { children: _jsx(IconButton, __assign({ className: styles.downloadIcon, onClick: function () { return filesMap[filePathValue].signed; } }, { children: _jsx(DownloadIcon, {}) })) }))) }))] }, filePathValue)); };
    var renderTableBodyRows = function () {
        var _a;
        if (((_a = request.body) === null || _a === void 0 ? void 0 : _a.status) === 'FAILED') {
            return Object.keys(filesMap)
                .filter(function (filePath) { return filePath.includes('info') || filePath.includes('warning'); })
                .map(function (filePath) { return renderRows(filePath); });
        }
        if (fileCount > 0 && request.body.status === 'COMPLETED') {
            return Object.keys(filesMap).map(function (filePath) { return renderRows(filePath); });
        }
        return (_jsx(TableRow, { children: _jsx(TableCell, __assign({ className: styles.noResultsCell, colSpan: 3 }, { children: "No Output Files" })) }));
    };
    return (_jsxs(StyledDialog, __assign({ isOpen: isOpen, closeModal: closeModal }, { children: [_jsx(CatalogRequestsModelHeader, { requestBody: request.body }), _jsxs(Box, __assign({ className: styles.filesContainer }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: "Output Files" })), loading || filesLoading ? (_jsx(Loading, {})) : (_jsxs(StyledTableContainer, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Type" }), _jsx(TableCell, { children: "Name" }), _jsx(TableCell, { children: " " })] }) }), _jsx(TableBody, { children: renderTableBodyRows() })] })), warningsLoading ? (_jsx(Loading, {})) : (warnings.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ variant: "h2", sx: { marginTop: '12px' } }, { children: "Warnings" })), _jsxs(StyledTableContainer, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: _jsx(TableCell, { children: "Description" }) }) }), _jsx(TableBody, { children: warnings.map(function (el) { return (_jsx(TableRow, { children: _jsx(TableCell, { children: el.description }) }, el.sk)); }) })] })] })))] }))] })));
}
