var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { useMemo, useState } from 'react';
import { useSnackbar } from 'context/snackbar/SnackBarProvider';
import { tryCopy } from 'utils/clipboardHelper';
import { manufacturingSiteService } from 'services/api/manufacturing-site-service';
import { DateTime } from 'luxon';
import { ManufacturingSiteModal } from './ManufacturingSiteModal/ManufacturingSiteModal';
import { QuickLink } from './QuickLink/QuickLink';
import { ReactComponent as ContractPriceIcon } from '../../../../assets/contractPrice.svg';
import { ReactComponent as QuickPriceIcon } from '../../../../assets/quickPrice.svg';
import { ReactComponent as PriceWOContractIcon } from '../../../../assets/priceWithoutContract.svg';
import { ReactComponent as FeatureAndOptionsIcon } from '../../../../assets/featuresAndOptions.svg';
import { ReactComponent as BaanIcon } from '../../../../assets/manufacturing.svg';
import { ReactComponent as CustomProductIcon } from '../../../../assets/customProduct.svg';
import styles from './BuildBox.module.scss';
export function BuildBox(_a) {
    var _this = this;
    var latestValidateResponse = _a.latestValidateResponse, timestamp = _a.timestamp, channelVal = _a.channelVal, buildMode = _a.buildMode, routeItems = _a.routeItems;
    var setSnackbarAlert = useSnackbar().setSnackbarAlert;
    var _b = useState(false), isFetchingBaanData = _b[0], setIsFetchingBaanData = _b[1];
    var _c = useState(), baanResponse = _c[0], setBaanResponse = _c[1];
    var _d = useState(false), isBaanModalOpen = _d[0], setIsBaanModalOpen = _d[1];
    var _e = useState(), baanIdForProductAndSession = _e[0], setBaanIdForProductAndSession = _e[1];
    var _f = useState(), baanErrorMsg = _f[0], setBaanErrorMsg = _f[1];
    var calculateMinLeadTime = function (val) {
        if (val) {
            if (val !== 99) {
                return "".concat(val, " Days");
            }
            return 'Assigned';
        }
        return '';
    };
    var buildBoxData = useMemo(function () {
        var _a, _b, _c;
        var data = {
            productNbr: (_a = latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.productNbrOut) !== null && _a !== void 0 ? _a : '',
            baseProductNbr: (_b = latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.baseProductNbr) !== null && _b !== void 0 ? _b : '',
            baseProductDescription: (_c = latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.baseProductDescription) !== null && _c !== void 0 ? _c : '',
            isPartial: (latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.returnCode) !== '0',
            leadTimeString: calculateMinLeadTime(latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.minLeadtime),
        };
        return data;
    }, [latestValidateResponse]);
    var leftQuickLinks = useMemo(function () {
        var arr = [
            {
                label: 'View Quick Price',
                icon: _jsx(QuickPriceIcon, {}),
            },
            {
                label: 'View Price Without Contract',
                icon: (_jsx(Box, { children: _jsx(PriceWOContractIcon, {}) })),
            },
            {
                label: 'View Contract Price',
                icon: _jsx(ContractPriceIcon, {}),
            },
        ];
        return arr;
    }, []);
    var rightQuickLinks = useMemo(function () {
        var arr = [
            {
                label: 'Go to Product Info',
                toRoute: "/product-info-v2/info?prefix=".concat(latestValidateResponse.productPrefix, "&date=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.date, "&time=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.time, "&channel=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.channelValue, "&pid=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.productId, "&productVersion=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.productVersion, "&origin=simple"),
                icon: (_jsx(Box, { children: _jsx(FeatureAndOptionsIcon, {}) })),
            },
            {
                label: 'Make Custom Product',
                toRoute: "/cpa?productNbr=".concat(latestValidateResponse === null || latestValidateResponse === void 0 ? void 0 : latestValidateResponse.inputProductNbr),
                icon: _jsx(CustomProductIcon, {}),
                isCustomProduct: true,
            },
            {
                label: 'Open Manufacturing Site',
                icon: _jsx(BaanIcon, {}),
                fireInParent: true,
            },
        ];
        if (!buildMode) {
            arr.push({
                label: 'Use for Product Build',
                toRoute: "/product-build-v2/build?productNbr=".concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.text, "&date=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.date, "&time=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.time, "&channel=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.channelValue, "&pid=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.productId, "&productVersion=").concat(routeItems === null || routeItems === void 0 ? void 0 : routeItems.productVersion),
                icon: (_jsx(Box, __assign({ sx: { width: '30px' } }, { children: _jsx(EditIcon, { sx: { color: 'darkgray', height: '20px', width: '30px' } }) }))),
                action: 'activateBuildMode',
            });
        }
        return arr;
    }, [latestValidateResponse, buildMode, routeItems]);
    var handleCopyClick = function (code) {
        if (code === -1) {
            setSnackbarAlert({
                message: "Looks like your browser does not support automatic copying. Please copy the JSON.",
                type: 'error',
            });
        }
    };
    var getBaanData = function (id, iteration, timeoutVal) { return __awaiter(_this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            setIsFetchingBaanData(true);
            setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                var rsp, obj, productLineCode, productName, productNbrOut, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, manufacturingSiteService.getDataFromManufacturingSite(id)];
                        case 1:
                            rsp = _a.sent();
                            if (rsp.status === 200) {
                                if (rsp.body.status === 'IN_PROGRESS' && iteration < 6) {
                                    getBaanData(id, iteration + 1, 10000);
                                }
                                else {
                                    setIsFetchingBaanData(false);
                                    setBaanIdForProductAndSession(id);
                                    obj = rsp.body.response;
                                    obj.productLineCode = latestValidateResponse.productLineCode;
                                    obj.effectiveDate = DateTime.now().toFormat('MM/dd/yyyy');
                                    setBaanResponse(obj);
                                    setIsBaanModalOpen(true);
                                    sessionStorage.removeItem('baanDataTransferring');
                                }
                            }
                            else if (rsp.status === 404 || rsp.status === 400) {
                                setIsFetchingBaanData(false);
                                setBaanIdForProductAndSession(id);
                                productLineCode = latestValidateResponse.productLineCode, productName = latestValidateResponse.productName, productNbrOut = latestValidateResponse.productNbrOut;
                                data = {
                                    productNumber: productNbrOut,
                                    productLineCode: productLineCode,
                                    productDescription: productName,
                                    effectiveDate: DateTime.now().toFormat('MM/dd/yyyy'),
                                };
                                setBaanResponse(data);
                                setBaanErrorMsg(rsp.body.message);
                                setIsBaanModalOpen(true);
                                sessionStorage.removeItem('baanDataTransferring');
                            }
                            else if (rsp.status === 500) {
                                setSnackbarAlert({
                                    message: "Manufacturing site process encountered an error - Service currently not available",
                                    type: 'error',
                                });
                            }
                            return [2 /*return*/];
                    }
                });
            }); }, timeoutVal);
            return [2 /*return*/];
        });
    }); };
    var handleAction = function () { return __awaiter(_this, void 0, void 0, function () {
        var rsp, id;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    sessionStorage.setItem('baanDataTransferring', 'true');
                    setIsFetchingBaanData(true);
                    if (baanIdForProductAndSession) {
                        getBaanData(baanIdForProductAndSession, 1, 0);
                        return [2 /*return*/];
                    }
                    setSnackbarAlert({
                        message: "Loading data from manufacturing site - process will time out after 60 seconds",
                        type: 'success',
                    });
                    return [4 /*yield*/, manufacturingSiteService.getIdForManufacturingSiteRequest({
                            productNumber: latestValidateResponse.productNbrOut,
                            productDescription: latestValidateResponse.baseProductDescription,
                        })];
                case 1:
                    rsp = _a.sent();
                    id = rsp.body.requestId;
                    getBaanData(id, 1, 10000);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ className: styles.buildBoxContainer, sx: {
                    backgroundColor: buildBoxData.isPartial ? 'rgb(253,248,243)' : 'rgba(0,0,0,0.1)',
                } }, { children: [_jsxs(Box, __assign({ className: styles.rowOneContainer }, { children: [_jsx(Typography, __assign({ sx: {
                                    color: buildBoxData.isPartial ? 'rgb(181,80,24)' : 'black',
                                }, className: styles.productNbrLabel }, { children: "Product Number" })), buildBoxData.isPartial ? (_jsxs(Box, __assign({ className: styles.compIncompClass }, { children: [_jsx(ErrorIcon, { sx: { color: 'rgb(181,80,24)', width: '18px' } }), _jsx(Typography, __assign({ sx: {
                                            color: 'rgb(181,80,24)',
                                        }, className: styles.label }, { children: "Incomplete Product" }))] }))) : (_jsxs(Box, __assign({ className: styles.compIncompClass }, { children: [_jsx(CheckIcon, { sx: { color: 'rgb(54,124,95)', width: '18px' } }), _jsx(Typography, __assign({ sx: {
                                            color: 'rgb(54,124,95)',
                                        }, className: styles.label }, { children: "Complete Product" }))] })))] })), _jsxs(Box, __assign({ className: styles.rowTwoContainer }, { children: [_jsx(Typography, __assign({ className: styles.productNbrVal }, { children: buildBoxData.productNbr })), !buildBoxData.isPartial && (_jsx(IconButton, __assign({ className: styles.copyButton, onClick: function () { return handleCopyClick(tryCopy(buildBoxData.productNbr)); } }, { children: _jsx(ContentCopyIcon, { className: styles.icon }) })))] })), _jsxs(Box, __assign({ className: styles.rowThreeContainer }, { children: [_jsxs(Box, __assign({ className: styles.leadTimeContainer }, { children: [_jsx(Typography, __assign({ className: styles.title }, { children: "Calculated Lead Time" })), _jsx(Typography, __assign({ className: styles.val }, { children: buildBoxData.leadTimeString }))] })), _jsxs(Box, __assign({ className: styles.leadTimeContainer }, { children: [_jsx(Typography, __assign({ className: styles.title }, { children: "Base Product Number" })), _jsx(Typography, __assign({ className: styles.val }, { children: buildBoxData.baseProductNbr }))] }))] })), _jsxs(Box, __assign({ className: styles.rowFourContainer }, { children: [_jsx(Typography, __assign({ className: styles.title }, { children: "Base Product Description" })), _jsx(Typography, __assign({ className: styles.val }, { children: buildBoxData.baseProductDescription }))] }))] })), _jsxs(Box, __assign({ className: styles.quickLinkContainer }, { children: [_jsx(Box, __assign({ sx: {
                            borderRight: buildBoxData.isPartial ? '1px solid lightgray' : '1px solid black',
                        }, className: styles.leftSide }, { children: leftQuickLinks.map(function (link) { return (_jsx(QuickLink, { label: link.label, toRoute: link === null || link === void 0 ? void 0 : link.toRoute, icon: link.icon, isDisabled: buildBoxData.isPartial, modalData: latestValidateResponse, timestamp: timestamp, channelVal: channelVal }, link.label)); }) })), _jsx(Box, __assign({ className: styles.rightSide }, { children: rightQuickLinks.map(function (link) { return (_jsx(QuickLink, { label: link.label, isCustomProduct: link.isCustomProduct, toRoute: link === null || link === void 0 ? void 0 : link.toRoute, icon: link.icon, isDisabled: buildBoxData.isPartial, action: link.action, fireActionInParent: link.fireInParent, handleActionInParent: function () { return handleAction(); }, isFetchingBaanData: isFetchingBaanData }, link.label)); }) }))] })), isBaanModalOpen && (_jsx(Box, __assign({ position: "absolute" }, { children: _jsx(ManufacturingSiteModal, { isOpen: true, data: baanResponse, errorMessage: baanErrorMsg, setIsOpen: setIsBaanModalOpen }) })))] }));
}
